
  <nb-layout>
      <nb-layout-column>
        <nb-card>
          <nb-card-body>
          
            <router-outlet></router-outlet>
          
          </nb-card-body>
        </nb-card>
        
        <div class="row justify-content-center m-3">
          <span class="created-by ">© {{year}} <b><a href="http://tjek.be" target="_blank">Tjek® </a></b><b>  Ouderportaal</b> </span>
        </div>

      </nb-layout-column>
    </nb-layout>
  