var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { NbAuthSocialLink } from '@nebular/auth/auth.options';
import { getDeepFromObject } from '@nebular/auth/helpers';
import { NbAuthService } from '@nebular/auth/services/auth.service';
import { NbRegisterComponent } from '@nebular/auth';
import { UserService } from '../../../../@core/data/users.service';
var NgxRegisterComponent = /** @class */ (function (_super) {
    __extends(NgxRegisterComponent, _super);
    function NgxRegisterComponent(service, userService, options, cd, router) {
        if (options === void 0) { options = {}; }
        var _this = _super.call(this, service, options, cd, router) || this;
        _this.service = service;
        _this.userService = userService;
        _this.options = options;
        _this.cd = cd;
        _this.router = router;
        _this.redirectDelay = 0;
        _this.showMessages = {};
        _this.strategy = '';
        _this.submitted = false;
        _this.errors = [];
        _this.messages = [];
        _this.user = {};
        _this.socialLinks = [];
        _this.redirectDelay = _this.getConfigValue('forms.register.redirectDelay');
        _this.showMessages = _this.getConfigValue('forms.register.showMessages');
        _this.strategy = _this.getConfigValue('forms.register.strategy');
        _this.socialLinks = _this.getConfigValue('forms.login.socialLinks');
        return _this;
    }
    NgxRegisterComponent.prototype.register = function () {
        var _this = this;
        this.errors = [];
        this.messages = [];
        this.submitted = true;
        // this.service.register(this.strategy, this.user).subscribe((result: NbAuthResult) => {
        //   this.submitted = false;
        //   this.authResult = result.getResponse();
        //   this.errors = [];
        //   this.messages = [];
        //   if (this.authResult.body.isSuccess) {
        //     this.messages.push(this.authResult.body.message);
        //     const redirect = result.getRedirect();
        //     if (redirect) {
        //       setTimeout(() => {
        //         return this.router.navigateByUrl(redirect);
        //       }, this.redirectDelay);
        //     }
        //   } else {
        //     this.errors.push(this.authResult.body.message);
        //   }
        //   this.cd.detectChanges();
        // });
        this.userService.parentRegister(this.user).subscribe(function (result) {
            _this.submitted = false;
            //this.authResult = result.getResponse();
            _this.errors = [];
            _this.messages = [];
            if (result.isSuccess) {
                _this.messages.push(result.message.toString());
            }
            else {
                _this.errors.push(result.message.toString());
            }
            _this.cd.detectChanges();
        });
    };
    NgxRegisterComponent.prototype.getConfigValue = function (key) {
        return getDeepFromObject(this.options, key, null);
    };
    return NgxRegisterComponent;
}(NbRegisterComponent));
export { NgxRegisterComponent };
