import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  TemplateRef
} from "@angular/core";
import { NbLoginComponent, NbAuthResult } from "@nebular/auth";
import { Router, ActivatedRoute } from "@angular/router";
import { NB_AUTH_OPTIONS, NbAuthSocialLink } from "@nebular/auth/auth.options";
import { getDeepFromObject } from "@nebular/auth/helpers";

import { NbAuthService } from "@nebular/auth/services/auth.service";
import { NgxAuthResult } from "../../../../@core/data/NgxAuthResult";
import { UserService } from "../../../../@core/data/users.service";
import { NbDialogService } from "@nebular/theme";

import { SubscriptionService } from "../../../../@core/data/subscription.service";
import { IResponse } from "../../../../@core/data/response";
export interface LocationList {
  location_id: number;
  location_Name: string;
}

@Component({
  selector: "nb-login",
  styleUrls: ["./login.component.scss"],
  templateUrl: "./login.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NgxLoginComponent extends NbLoginComponent {
  redirectDelay: number = 0;
  showMessages: any = {};
  strategy: string = "";
  errors: string[] = [];
  messages: string[] = [];
  user: any = {
    email:""
  };
  submitted: boolean = false;
  selectedLocation: number;
  multilocations: [];
  locdialog: TemplateRef<any>;
  private ParentLocations: LocationList[];
  // socialLinks: NbAuthSocialLink[] = [];
  rememberMe = false;
  constructor(
    protected service: NbAuthService,
    @Inject(NB_AUTH_OPTIONS) protected options = {},
    protected cd: ChangeDetectorRef,
    protected router: Router,
    protected userService: UserService,
    private route: ActivatedRoute,
    private dialogService: NbDialogService,
    private subService: SubscriptionService
  ) {
    super(service, options, cd, router);
    this.redirectDelay = this.getConfigValue("forms.login.redirectDelay");
    this.showMessages = this.getConfigValue("forms.login.showMessages");
    this.strategy = this.getConfigValue("forms.login.strategy");
    this.socialLinks = this.getConfigValue("forms.login.socialLinks");
    //this.rememberMe = this.getConfigValue("forms.login.rememberMe");
    this.rememberMe = (localStorage.getItem("rememberMe") == "Y");
    if(this.rememberMe){
      this.user.email = (localStorage.getItem("email") ? localStorage.getItem("email") : "");
    }
    this.route.queryParams.subscribe(params => {
      if(params !=null && params != undefined && params['email'] != null && params['email'] != undefined){
        this.user.email = params["email"];
      }
    });
  }
  // getLocation(dialog: TemplateRef<any>) {
  //   this.errors = [];
  //   this.messages = [];
  //   if (this.user.password.length > 4) {
  //     this.subService
  //       .getParentLocations(this.user)
  //       .subscribe((data: IResponse) => {
  //         debugger;
  //         let list: LocationList[] = data.locations;
  //         if (data.isSuccess && list.length > 1) {
  //           this.ParentLocations = list;
  //           this.dialogService
  //             .open(dialog, {
  //               closeOnBackdropClick: false,
  //               context: {
  //                 LocationList: this.ParentLocations
  //               }
  //             })
  //             .onClose.subscribe(location => location && this.selectedLocation);
  //         } else {
  //           this.user.location_id = list[0]["location_id"];
  //           this.login();
  //         }
  //       });
  //   } else {
  //     this.login();
  //   }
  // }
  login1(dialog: TemplateRef<any>): void {
    this.locdialog = dialog;
    this.multilocations = [];
    if(this.rememberMe){
      localStorage.setItem("rememberMe","Y");
      localStorage.setItem("email",this.user.email);
    } else{
      localStorage.removeItem("rememberMe");
      localStorage.removeItem("email");
    }
    this.login();
  }
  login(): void {
    this.errors = [];
    this.messages = [];
    this.submitted = true;

    //debugger;

    this.service
      .authenticate(this.strategy, this.user)
      .subscribe((result: NbAuthResult) => {
        this.submitted = false;

        this.errors = [];
        this.messages = [];
        if (result.isSuccess()) {
          this.userService.setLoggedUser(result.getResponse().body);
          this.messages = result.getMessages();
          const redirect = result.getRedirect();
          if (redirect) {
            setTimeout(() => {
              return this.router.navigateByUrl(redirect);
            }, this.redirectDelay);
          }
        } else {
          //console.log(result.getResponse().body);
          if (result.getResponse().error.locations && result.getResponse().error.locations.length > 1) {
            this.multilocations = result.getResponse().error.locations;
            if (this.multilocations && this.multilocations.length > 0) {
              this.dialogService
                .open(this.locdialog, {
                  closeOnBackdropClick: false,
                  context: {
                    LocationList: this.multilocations
                  }
                })
                .onClose.subscribe(location => {
                  this.login();
                  this.cd.detectChanges();
                });
            } else {
              this.cd.detectChanges();
            }
          } else {
            if(result.getResponse().error && result.getResponse().error.message && result.getResponse().error.message.length > 0){
              this.errors.push(result.getResponse().error.message);
            }else{
              this.errors = result.getErrors();
            }
          }

          this.cd.detectChanges();
        }
      });
  }

  getConfigValue(key: string): any {
    return getDeepFromObject(this.options, key, null);
  }
  onSelectParentLocation(loc: number) {
    this.user.location_id = loc;
  }
}
