import { ModuleWithProviders, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import {
  NbActionsModule,
  NbCardModule,
  NbLayoutModule,
  NbMenuModule,
  NbRouteTabsetModule,
  //NbSearchModule,
  NbSidebarModule,
  NbTabsetModule,
  NbThemeModule,
  NbUserModule,
  NbCheckboxModule,
  NbPopoverModule,
  NbContextMenuModule,
  NbProgressBarModule,
 // NbCalendarModule,
  //NbCalendarRangeModule,
  NbStepperModule,
  NbButtonModule,
  NbInputModule,
  //NbAccordionModule,
  //NbDatepickerModule,
  NbDialogModule,
  NbWindowModule,
  NbListModule,
  NbToastrModule,
  NbAlertModule,
  NbSpinnerModule,
  NbTooltipModule,
 
  NbAccordionModule,
 // NbRadioModule,
} from "@nebular/theme";

import { NbSecurityModule } from "@nebular/security";

import {
  FooterComponent,
  HeaderComponent,
  //SearchInputComponent,
  //ThemeSettingsComponent,
 // SwitcherComponent,
  //LayoutDirectionSwitcherComponent,
  //ThemeSwitcherComponent,
  TinyMCEComponent,
  //ThemeSwitcherListComponent
} from "./components";
import {
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithCommasPipe,
  EvaIconsPipe
} from "./pipes";
import {
  //OneColumnLayoutComponent,
  SampleLayoutComponent,
  //ThreeColumnsLayoutComponent,
  //TwoColumnsLayoutComponent
} from "./layouts";
//import { DEFAULT_THEME } from "./styles/theme.default";
//import { COSMIC_THEME } from "./styles/theme.cosmic";
import { CORPORATE_THEME } from "./styles/theme.corporate";
import { NotFoundComponent } from "./components/not-found/not-found.component";
import { AmazingTimePickerModule } from "amazing-time-picker";
import { TimeFormat24to12Pipe } from "./pipes/time-format-24to12.pipe";
import { SubFilterPipe } from "./pipes/sub-filter.pipe";
import { NewSessionStartDialogComponent } from "./components/new-session-start-dialog/new-session-start-dialog.component";
import { UnsubscribeComponent } from './components/unsubscribe/unsubscribe.component';

const BASE_MODULES = [CommonModule, FormsModule, ReactiveFormsModule];

const NB_MODULES = [
  AmazingTimePickerModule,
  NbCardModule,
  NbLayoutModule,
  NbTabsetModule,
  NbRouteTabsetModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbTooltipModule,
  //NbSearchModule,
  NbSidebarModule,
  NbCheckboxModule,
  NbPopoverModule,
  NbContextMenuModule,
  NgbModule,
  NbSecurityModule,
  NbProgressBarModule,
  //NbCalendarModule,
  //NbCalendarRangeModule,
  NbStepperModule,
  NbButtonModule,
  NbListModule,
  NbToastrModule,
  NbInputModule,
  //NbAccordionModule,
  //NbDatepickerModule,
  NbDialogModule,
  NbWindowModule,
  NbAlertModule,
  NbSpinnerModule,
  //NbRadioModule,
  //NbSelectModule,
  //NbChatModule,
  NbAccordionModule
  //NbCalendarKitModule
];

const COMPONENTS = [
  UnsubscribeComponent,
  NotFoundComponent,
  //SwitcherComponent,
 // LayoutDirectionSwitcherComponent,
 // ThemeSwitcherComponent,
  //ThemeSwitcherListComponent,
  HeaderComponent,
  FooterComponent,
  //SearchInputComponent,
  //ThemeSettingsComponent,
  TinyMCEComponent,
  //OneColumnLayoutComponent,
  SampleLayoutComponent,
  //ThreeColumnsLayoutComponent,
  //TwoColumnsLayoutComponent,
  NewSessionStartDialogComponent
];

const ENTRY_COMPONENTS = [
  //ThemeSwitcherListComponent,
  NewSessionStartDialogComponent
];

const PIPES = [
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithCommasPipe,
  EvaIconsPipe,
  TimeFormat24to12Pipe,
  SubFilterPipe
];

const NB_THEME_PROVIDERS = [
  ...NbThemeModule.forRoot(
    {
      name: "corporate"
    },
    [CORPORATE_THEME]
  ).providers,
  ...NbSidebarModule.forRoot().providers,
  ...NbMenuModule.forRoot().providers,
  ...NbDialogModule.forRoot().providers,
  ...NbWindowModule.forRoot().providers,
  ...NbToastrModule.forRoot().providers
];

@NgModule({
  imports: [...BASE_MODULES, ...NB_MODULES],
  exports: [...BASE_MODULES, ...NB_MODULES, ...COMPONENTS, ...PIPES],
  declarations: [...COMPONENTS, ...PIPES],
  entryComponents: [...ENTRY_COMPONENTS]
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: ThemeModule,
      providers: [...NB_THEME_PROVIDERS]
    };
  }
}
