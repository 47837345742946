<div class="row">
  <div class="col-md-12">
      <div id="notfound">
          <div class="notfound">
            <div class="notfound-404" >
              <h1 class="">4<span>0</span>4</h1>
            </div>
            <h2 class="m-2">THE PAGE YOU REQUESTED COULD NOT FOUND</h2>
          </div>
        </div>
  </div>
</div>