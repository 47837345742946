import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-no-internet',
  templateUrl: './no-internet.component.html',
  styleUrls: ['./no-internet.component.scss']
})
export class NoInternetComponent implements OnInit {

  public IconOption = {
    height: 100,
    width: 100,
    fill: "#fff",
    animationType: "shake",
    animationHover: true,
    animationInfinity: false,
  }

  constructor() { }

  ngOnInit() {
  }

}
