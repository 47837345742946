var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Router } from '@angular/router';
import { NbLogoutComponent } from '@nebular/auth';
import { getDeepFromObject } from '@nebular/auth/helpers';
import { NbAuthService } from '@nebular/auth/services/auth.service';
var NgxLogoutComponent = /** @class */ (function (_super) {
    __extends(NgxLogoutComponent, _super);
    function NgxLogoutComponent(service, options, router) {
        if (options === void 0) { options = {}; }
        var _this = _super.call(this, service, options, router) || this;
        _this.service = service;
        _this.options = options;
        _this.router = router;
        _this.redirectDelay = 0;
        _this.strategy = '';
        _this.redirectDelay = _this.getConfigValue('forms.logout.redirectDelay');
        _this.strategy = _this.getConfigValue('forms.logout.strategy');
        return _this;
    }
    NgxLogoutComponent.prototype.ngOnInit = function () {
        this.logout(this.strategy);
    };
    NgxLogoutComponent.prototype.logout = function (strategy) {
        var _this = this;
        this.service.logout(strategy).subscribe(function (result) {
            var redirect = result.getRedirect();
            if (redirect) {
                setTimeout(function () {
                    return _this.router.navigateByUrl(redirect);
                }, _this.redirectDelay);
            }
        });
    };
    NgxLogoutComponent.prototype.getConfigValue = function (key) {
        return getDeepFromObject(this.options, key, null);
    };
    return NgxLogoutComponent;
}(NbLogoutComponent));
export { NgxLogoutComponent };
