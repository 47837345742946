
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { NB_AUTH_OPTIONS, NbAuthSocialLink } from '@nebular/auth/auth.options';
import { getDeepFromObject } from '@nebular/auth/helpers';

import { NbAuthService } from '@nebular/auth/services/auth.service';
import { NbAuthResult } from '@nebular/auth/services/auth-result';
import { NbRegisterComponent } from '@nebular/auth';
import { IAuthResponse } from '../../../../@core/data/authResponse';
import { UserService } from '../../../../@core/data/users.service';
import { IResponse } from '../../../../@core/data/response';


@Component({
  selector: 'nb-register',
  styleUrls: ['./register.component.scss'],
  templateUrl: './register.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NgxRegisterComponent extends NbRegisterComponent {

  redirectDelay: number = 0;
  showMessages: any = {};
  strategy: string = '';
  authResult: IAuthResponse;
  submitted = false;
  errors: string[] = [];
  messages: string[] = [];
  user: any = {};
  socialLinks: NbAuthSocialLink[] = [];

  constructor(protected service: NbAuthService,
    protected userService: UserService,
    @Inject(NB_AUTH_OPTIONS) protected options = {},
    protected cd: ChangeDetectorRef,
    protected router: Router) {

    super(service, options, cd, router);

    this.redirectDelay = this.getConfigValue('forms.register.redirectDelay');
    this.showMessages = this.getConfigValue('forms.register.showMessages');
    this.strategy = this.getConfigValue('forms.register.strategy');
    this.socialLinks = this.getConfigValue('forms.login.socialLinks');
  }

  register(): void {
    this.errors = [];
    this.messages = [];
    this.submitted = true;

    // this.service.register(this.strategy, this.user).subscribe((result: NbAuthResult) => {
    //   this.submitted = false;
    //   this.authResult = result.getResponse();

    //   this.errors = [];
    //   this.messages = [];
    //   if (this.authResult.body.isSuccess) {
    //     this.messages.push(this.authResult.body.message);
    //     const redirect = result.getRedirect();
    //     if (redirect) {
    //       setTimeout(() => {
    //         return this.router.navigateByUrl(redirect);
    //       }, this.redirectDelay);
    //     }
    //   } else {
    //     this.errors.push(this.authResult.body.message);
    //   }

    //   this.cd.detectChanges();
    // });

    this.userService.parentRegister(this.user).subscribe((result: IResponse) => {
    this.submitted = false;
    //this.authResult = result.getResponse();

    this.errors = [];
    this.messages = [];
    if (result.isSuccess) {
      this.messages.push(result.message.toString());
    } else {
      this.errors.push(result.message.toString());
    }

    this.cd.detectChanges();
  });
  
  }

  getConfigValue(key: string): any {
    return getDeepFromObject(this.options, key, null);
  }
}
