<nb-layout>
  <nb-layout-column>
      <nb-card>
          <nb-card-body>
                
<form
#form="ngForm"
aria-labelledby="title"
>
<nb-alert *ngIf="showMessages.error && errors?.length && !submitted" outline="danger" role="alert">
    <p class="alert-title"><b>Oeps!</b></p>
    <ul class="alert-message-list">
      <li *ngFor="let error of errors" class="alert-message">{{ error }}</li>
    </ul>
  </nb-alert>
  
  <nb-alert *ngIf="showMessages.success && messages?.length && !submitted" outline="success" role="alert">
    <p class="alert-title"><b>Hoera!</b></p>
    <ul class="alert-message-list">
      <li *ngFor="let message of messages" class="alert-message">{{ message }}</li>
    </ul>  
  </nb-alert>
<h1>Unsubscribe</h1>
<hr/>
                <p>To unsubscribe, please check the appropriate box(es).</p>
                <nb-list>
                    <nb-list-item>
                        <div class="">
                            <nb-checkbox
                            name="sub_invite_email"
                            id="sub_invite_email"
                            [(ngModel)]="unsubscriber.subscriptions.sub_invite_email"      
                            ></nb-checkbox>
                            <label class="label" for="input-sub_invite_email"><strong>Subscription Invitation Email</strong><p>Please see</p> </label>
                                                       
                          </div>
                    </nb-list-item>
                  </nb-list>
                  <hr/>
                  <div class="form-control-group float-right">
                  <button nbButton status="success" [class.btn-pulse]="submitted" 
                  (click)="unsubscribe();">
                    Unsubscribe
                  </button>
                  </div>
</form>
          </nb-card-body>
        </nb-card>

  </nb-layout-column>
</nb-layout>