var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, TemplateRef } from "@angular/core";
import { NbLoginComponent } from "@nebular/auth";
import { Router, ActivatedRoute } from "@angular/router";
import { getDeepFromObject } from "@nebular/auth/helpers";
import { NbAuthService } from "@nebular/auth/services/auth.service";
import { UserService } from "../../../../@core/data/users.service";
import { NbDialogService } from "@nebular/theme";
import { SubscriptionService } from "../../../../@core/data/subscription.service";
var NgxLoginComponent = /** @class */ (function (_super) {
    __extends(NgxLoginComponent, _super);
    function NgxLoginComponent(service, options, cd, router, userService, route, dialogService, subService) {
        if (options === void 0) { options = {}; }
        var _this = _super.call(this, service, options, cd, router) || this;
        _this.service = service;
        _this.options = options;
        _this.cd = cd;
        _this.router = router;
        _this.userService = userService;
        _this.route = route;
        _this.dialogService = dialogService;
        _this.subService = subService;
        _this.redirectDelay = 0;
        _this.showMessages = {};
        _this.strategy = "";
        _this.errors = [];
        _this.messages = [];
        _this.user = {
            email: ""
        };
        _this.submitted = false;
        // socialLinks: NbAuthSocialLink[] = [];
        _this.rememberMe = false;
        _this.redirectDelay = _this.getConfigValue("forms.login.redirectDelay");
        _this.showMessages = _this.getConfigValue("forms.login.showMessages");
        _this.strategy = _this.getConfigValue("forms.login.strategy");
        _this.socialLinks = _this.getConfigValue("forms.login.socialLinks");
        //this.rememberMe = this.getConfigValue("forms.login.rememberMe");
        _this.rememberMe = (localStorage.getItem("rememberMe") == "Y");
        if (_this.rememberMe) {
            _this.user.email = (localStorage.getItem("email") ? localStorage.getItem("email") : "");
        }
        _this.route.queryParams.subscribe(function (params) {
            if (params != null && params != undefined && params['email'] != null && params['email'] != undefined) {
                _this.user.email = params["email"];
            }
        });
        return _this;
    }
    // getLocation(dialog: TemplateRef<any>) {
    //   this.errors = [];
    //   this.messages = [];
    //   if (this.user.password.length > 4) {
    //     this.subService
    //       .getParentLocations(this.user)
    //       .subscribe((data: IResponse) => {
    //         debugger;
    //         let list: LocationList[] = data.locations;
    //         if (data.isSuccess && list.length > 1) {
    //           this.ParentLocations = list;
    //           this.dialogService
    //             .open(dialog, {
    //               closeOnBackdropClick: false,
    //               context: {
    //                 LocationList: this.ParentLocations
    //               }
    //             })
    //             .onClose.subscribe(location => location && this.selectedLocation);
    //         } else {
    //           this.user.location_id = list[0]["location_id"];
    //           this.login();
    //         }
    //       });
    //   } else {
    //     this.login();
    //   }
    // }
    NgxLoginComponent.prototype.login1 = function (dialog) {
        this.locdialog = dialog;
        this.multilocations = [];
        if (this.rememberMe) {
            localStorage.setItem("rememberMe", "Y");
            localStorage.setItem("email", this.user.email);
        }
        else {
            localStorage.removeItem("rememberMe");
            localStorage.removeItem("email");
        }
        this.login();
    };
    NgxLoginComponent.prototype.login = function () {
        var _this = this;
        this.errors = [];
        this.messages = [];
        this.submitted = true;
        //debugger;
        this.service
            .authenticate(this.strategy, this.user)
            .subscribe(function (result) {
            _this.submitted = false;
            _this.errors = [];
            _this.messages = [];
            if (result.isSuccess()) {
                _this.userService.setLoggedUser(result.getResponse().body);
                _this.messages = result.getMessages();
                var redirect_1 = result.getRedirect();
                if (redirect_1) {
                    setTimeout(function () {
                        return _this.router.navigateByUrl(redirect_1);
                    }, _this.redirectDelay);
                }
            }
            else {
                //console.log(result.getResponse().body);
                if (result.getResponse().error.locations && result.getResponse().error.locations.length > 1) {
                    _this.multilocations = result.getResponse().error.locations;
                    if (_this.multilocations && _this.multilocations.length > 0) {
                        _this.dialogService
                            .open(_this.locdialog, {
                            closeOnBackdropClick: false,
                            context: {
                                LocationList: _this.multilocations
                            }
                        })
                            .onClose.subscribe(function (location) {
                            _this.login();
                            _this.cd.detectChanges();
                        });
                    }
                    else {
                        _this.cd.detectChanges();
                    }
                }
                else {
                    if (result.getResponse().error && result.getResponse().error.message && result.getResponse().error.message.length > 0) {
                        _this.errors.push(result.getResponse().error.message);
                    }
                    else {
                        _this.errors = result.getErrors();
                    }
                }
                _this.cd.detectChanges();
            }
        });
    };
    NgxLoginComponent.prototype.getConfigValue = function (key) {
        return getDeepFromObject(this.options, key, null);
    };
    NgxLoginComponent.prototype.onSelectParentLocation = function (loc) {
        this.user.location_id = loc;
    };
    return NgxLoginComponent;
}(NbLoginComponent));
export { NgxLoginComponent };
