var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { getDeepFromObject } from '@nebular/auth/helpers';
import { NbAuthService } from '@nebular/auth/services/auth.service';
import { NbResetPasswordComponent } from '@nebular/auth';
import { UserService } from '../../../../@core/data/users.service';
var NgxResetPasswordComponent = /** @class */ (function (_super) {
    __extends(NgxResetPasswordComponent, _super);
    function NgxResetPasswordComponent(service, options, userService, cd, router, route) {
        if (options === void 0) { options = {}; }
        var _this = _super.call(this, service, options, cd, router) || this;
        _this.service = service;
        _this.options = options;
        _this.userService = userService;
        _this.cd = cd;
        _this.router = router;
        _this.route = route;
        _this.redirectDelay = 0;
        _this.showMessages = {};
        _this.strategy = '';
        _this.submitted = false;
        _this.errors = [];
        _this.messages = [];
        _this.user = {};
        _this.route.queryParams.subscribe(function (params) {
            _this.token = params['token'];
        });
        _this.redirectDelay = _this.getConfigValue('forms.resetPassword.redirectDelay');
        _this.showMessages = _this.getConfigValue('forms.resetPassword.showMessages');
        _this.strategy = _this.getConfigValue('forms.resetPassword.strategy');
        return _this;
    }
    NgxResetPasswordComponent.prototype.ngOnInit = function () {
        this.user.token = this.token;
    };
    NgxResetPasswordComponent.prototype.resetPass = function () {
        var _this = this;
        this.errors = [];
        this.messages = [];
        this.submitted = true;
        this.user.token = this.token;
        this.userService.resetPass(this.user).subscribe(function (result) {
            _this.submitted = false;
            //this.authResult = result.getResponse();
            _this.errors = [];
            _this.messages = [];
            if (result.isSuccess) {
                _this.messages.push(result.message.toString());
            }
            else {
                _this.errors.push(result.message.toString());
            }
            _this.cd.detectChanges();
        });
    };
    NgxResetPasswordComponent.prototype.getConfigValue = function (key) {
        return getDeepFromObject(this.options, key, null);
    };
    return NgxResetPasswordComponent;
}(NbResetPasswordComponent));
export { NgxResetPasswordComponent };
