import { Component, OnInit } from '@angular/core';
import { ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { UserService } from "../../../@core/data/users.service";
import { IResponse } from '../../../@core/data/response';

@Component({
  selector: 'ngx-unsubscribe',
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnsubscribeComponent implements OnInit {

  public IconOption = {
    height: 100,
    width: 100,
    fill: "#fff",
    animationType: "shake",
    animationHover: true,
    animationInfinity: false,
  }
  unsubscriber = {
    id:"",
    token:"",
    subscriptions:{
      sub_invite_email:false
    }
  };
  errors: string[] = [];
  messages: string[] = [];
  submitted: boolean = false;
  showMessages: any = {};

  constructor(
    protected userService: UserService,
    protected cd: ChangeDetectorRef,
    protected router: Router,
    private route: ActivatedRoute) { 
      // this.spinner.hide();
    }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if(Object.keys(params).length>0){
         if(params['id'] != null && params['id'] != undefined){
          this.unsubscriber.id = params["id"];
         }
         if(params['token'] != null && params['token'] != undefined){
          this.unsubscriber.token = params["token"];
         }
      }
    });
  }

  unsubscribe(){
    
    this.errors = [];
    this.messages = [];
    this.submitted = true;
    if(this.unsubscriber.id == "" || this.unsubscriber.token == ""){
      this.submitted = false;
      this.errors.push("Unsubscription information is missing");
      this.showMessages.error = true;
    } else {
      this.userService.unsubscribe(this.unsubscriber).subscribe((result: IResponse) => {
        this.submitted = false;
        //this.authResult = result.getResponse();

        this.errors = [];
        this.messages = [];
      
        if (result.isSuccess) {
          this.showMessages.success = true;
          this.messages.push(result.message.toString());
        } else {
          this.showMessages.error = true;
          this.errors.push(result.message.toString());
        }

        this.cd.detectChanges();
      });
    }
  }
}
