
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { NB_AUTH_OPTIONS, NbAuthSocialLink } from '@nebular/auth/auth.options';
import { getDeepFromObject } from '@nebular/auth/helpers';

import { NbAuthService } from '@nebular/auth/services/auth.service';
import { NbAuthResult } from '@nebular/auth/services/auth-result';
import { NbRequestPasswordComponent } from '@nebular/auth';
import { IAuthResponse } from '../../../../@core/data/authResponse';
import { UserService } from '../../../../@core/data/users.service';
import { IResponse } from '../../../../@core/data/response';

@Component({
  selector: 'nb-request-password-page',
  styleUrls: ['./request-password.component.scss'],
  templateUrl: './request-password.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NgxRequestPasswordComponent extends NbRequestPasswordComponent {

  redirectDelay: number = 0;
  showMessages: any = {};
  strategy: string = '';

  submitted = false;
  errors: string[] = [];
  messages: string[] = [];
  user: any = {};
  authResult: IAuthResponse;

  constructor(protected service: NbAuthService,
    @Inject(NB_AUTH_OPTIONS) protected options = {},
    protected userService: UserService,
    protected cd: ChangeDetectorRef,
    protected router: Router) {

    super(service, options, cd, router);

    this.redirectDelay = this.getConfigValue('forms.requestPassword.redirectDelay');
    this.showMessages = this.getConfigValue('forms.requestPassword.showMessages');
    this.strategy = this.getConfigValue('forms.requestPassword.strategy');
  }

  requestPass(): void {
    this.errors = [];
    this.messages = [];
    this.submitted = true;
    
    this.userService.requestPass(this.user).subscribe((result: IResponse) => {
      this.submitted = false;
      this.errors = [];
      this.messages = [];
     // this.authResult = result.getResponse();

      if (result.isSuccess) {
        this.messages.push(result.message.toString());
      } else {
        this.errors.push(result.message.toString());
      }
      this.cd.detectChanges();
    });
  }

  getConfigValue(key: string): any {
    return getDeepFromObject(this.options, key, null);
  }
}
