import { of as observableOf, Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient, HttpParams,HttpHeaders } from "@angular/common/http";
import { NbAuthResult } from "@nebular/auth";
import { IResponse } from "./response";
import { environment } from "./../../../environments/environment";

const APIEndpoint = environment.APIEndpoint;

interface User {
  id: number;
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  activeLocationId: number;
  activeLocationName: string;
  activeLocationType: string;
  activeGroups: string;
  activeProductGroupId: number;
  activeProductGroupName: string;
  menuSchoolVisible: boolean;
  menuPurchasesVisible: boolean;
}

let counter = 0;

interface myData {
  data: string;
  success: boolean;
}

interface isLoggedIn {
  status: boolean;
}

interface logoutStatus {
  success: boolean;
}

let user = {} as User;
@Injectable()
export class UserService {
  private users = {
    nick: { name: "Nick Jones", picture: "assets/images/nick.png" },
    eva: { name: "Eva Moor", picture: "assets/images/eva.png" },
    jack: { name: "Jack Williams", picture: "assets/images/jack.png" },
    lee: { name: "Lee Wong", picture: "assets/images/lee.png" },
    alan: { name: "Alan Thompson", picture: "assets/images/alan.png" },
    kate: { name: "Kate Martinez", picture: "assets/images/kate.png" }
  };
  private getRandom():string {
    return Math.floor(Math.random()*1000000000000).toString();
  }

  setLoggedUser(loggedUser) {
    if (loggedUser) {
      user.id = loggedUser.user_id;
      user.firstName = loggedUser.user_firstname;
      user.lastName = loggedUser.user_lastname;
      user.username = loggedUser.user_name;
      user.activeLocationId = loggedUser.active_location_id;
      user.activeLocationName = loggedUser.active_location_name;
      user.activeLocationType = loggedUser.active_location_type;
      user.activeGroups = loggedUser.active_groups;
      user.activeProductGroupId = loggedUser.active_productgroup_id;
      user.activeProductGroupName = loggedUser.active_productgroup_name;
      user.menuSchoolVisible = loggedUser.menu_school_visible;
      user.menuPurchasesVisible = loggedUser.active_productgroup_name;
    }
  }

  getLoggedUser(): Observable<any> {
    return observableOf(user);
  }
  private userArray: any[];

  constructor(private http: HttpClient) {
    // this.userArray = Object.values(this.users);
  }

  getUsers(): Observable<any> {
    return observableOf(this.users);
  }

  getUserArray(): Observable<any[]> {
    return observableOf(this.userArray);
  }

  getUser(): Observable<any> {
    counter = (counter + 1) % this.userArray.length;
    return observableOf(this.userArray[counter]);
  }

  isLoggedIn(): Observable<isLoggedIn> {
    return this.http.get<isLoggedIn>("/validate-login?r="+Math.random()*100000);
  }

  logout() {
    return this.http.get<logoutStatus>("/api/logout.php?r="+Math.random()*100000);
  }

  parentRegister(user) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.http.post<IResponse>(APIEndpoint + "/register-parent?r="+Math.random()*100000, user,httpOptions).pipe(
      //catchError(er=>{console.log(er)});
    );
  }

  requestPass(usre) {
    return this.http.post<IResponse>(APIEndpoint + "/request-pass?r="+Math.random()*100000, usre);
  }

  resetPass(usre) {
    return this.http.post<IResponse>(APIEndpoint + "/reset-pass?r="+Math.random()*100000, usre);
  }
  unsubscribe(unsubscribe) {
    return this.http
      .post<IResponse>(APIEndpoint + "/unsubscribe?r="+Math.random()*100000, unsubscribe);
  }
}
