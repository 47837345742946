<nb-layout>
  <nb-layout-column>
    <div id="notfound">
      <div class="notfound">
        <div class="notfound-404">
          <!-- <h1 class=""><span> JE BENT NIET VERBONDEN MET HET INTERNET </span></h1> -->
          <h1 class=""><span> Internet is niet beschikbaar </span></h1>
        </div>
        <i class="wifi-off-outline-eva" [innerHTML]="'wifi-off-outline' | eva : IconOption"></i>
        <!-- <h2 class="m-2">Maak eerst een verbinding om de toepassing te kunnen gebruiken.</h2> -->
      </div>
    </div>
  </nb-layout-column>
</nb-layout>