import { Component, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';

import { NbAuthService } from '@nebular/auth/services/auth.service';
import { NbAuthComponent } from '@nebular/auth';

@Component({
  selector: 'nb-auth',
  styleUrls: ['./auth.component.scss'],
  template: `<router-outlet>
        <nb-auth-block>
        </nb-auth-block>
          </router-outlet>
          
  `,
})
export class NgxAuthComponent extends NbAuthComponent {

  // showcase of how to use the onAuthenticationChange method
  constructor(protected auth: NbAuthService, protected location: Location) {
    super(auth,location);
  }

}
