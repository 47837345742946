import { Pipe, PipeTransform } from '@angular/core';
export interface ISubFilter {
    view: String;
    locationId:Number;
}
@Pipe({
    name: 'subFilter',
    pure: false
})
export class SubFilterPipe implements PipeTransform {
    transform(items: any[], filter: ISubFilter): any {
        if (!items || !filter) {
            return items;
        }
        // filter items array, items which match and return true will be
        // kept, false will be filtered out
        if (filter.view == "cancel") {
            //debugger;
            return items.filter(item => (((item.subscription_registration_morning == 'Y' ||
                item.subscription_registration_reserve_morning == 'Y') || (item.subscription_registration_afternoon == 'Y' ||
                    item.subscription_registration_reserve_afternoon == 'Y')) 
                    && (item.subscription_registration_state == 3 || item.subscription_registration_state == 4)
                    && (item.subscription_registration_cancelled_morning == 'N' ||
                    item.subscription_registration_cancelled_afternoon == 'N')
                ));

        } else if (filter.view == "view") {
            //debugger;
            items.map(item =>{
                item.hideMorningView = (item.subscription_registration_location_morning != filter.locationId); item.hideAfternoonView = (item.subscription_registration_location_afternoon != filter.locationId);
            }); 
            return items.filter(item => 
                    ((item.subscription_registration_location_afternoon == filter.locationId || item.subscription_registration_location_morning == filter.locationId) &&
                        ((item.subscription_registration_morning == 'Y' ||
                    item.subscription_registration_reserve_morning == 'Y') || (item.subscription_registration_afternoon == 'Y' ||
                        item.subscription_registration_reserve_afternoon == 'Y'))));

        } else {
            return items;
        }
    }
}