import { Component, Input, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

import { NbMenuService, NbSidebarService } from '@nebular/theme';
import { UserService } from '../../../@core/data/users.service';
import { AnalyticsService } from '../../../@core/utils/analytics.service';
import { LayoutService } from '../../../@core/data/layout.service';
import { NbAuthService, NbAuthJWTToken } from '@nebular/auth';
import {NavigationEnd,Router} from '@angular/router';
/*import { GAService } from '../../../@core/data/ga.service';*/

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {

  @Input() position = 'normal';

  user = {
    user_id:0,
    location_name: '',
    user_firstname: '',
    user_lastname: ''
  };
  userMenu = [
    { title: 'Mijn profiel', icon: 'ion ion-ios-person-outline', url: '/home/myprofile' },
    { title: 'Afmelden', icon: 'ion ion-android-exit', url: '/auth/logout' }
  ];
  protected router:Router;
  menu:any;

  constructor(private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private userService: UserService,
    protected cd: ChangeDetectorRef,
    private analyticsService: AnalyticsService,
    private layoutService: LayoutService,
    private authService: NbAuthService,
    menu: NbMenuService,
    router: Router
    /*,
    private gaService: GAService*/) {
      this.menu = menu;
      this.router= router;

      // this.router.events.subscribe(event => {
      //   if (event instanceof NavigationEnd) {
      //     window["dataLayer"].push({
      //       "event": "gtm-spa-pageview",
      //       "spa-page": event.urlAfterRedirects
      //     });
      //   }
      // });
    this.authService.onTokenChange()
      .subscribe((token: NbAuthJWTToken) => {
        if (token.isValid()) {
          this.user = token.getPayload();
          if(this.user != null && this.user.user_lastname == null){
            this.user.user_lastname = "";
          }
          //console.log(this.user);
          window["dataLayer"].user = this.user.user_id;
        }

      });
  }

  ngOnInit() {
    
    // this.userService.getUser()
    //   .subscribe((user: any) => this.user = user);
    
    // this.menu.onItemClick()
    //   .subscribe((item: any) => {
    //     var menuid  =item.item.data.id; 
    //     var currentPage = localStorage.getItem("currentPage");
    //     var currentStep = localStorage.getItem("currentStep");
    //     //console.log(item.item.data.id);
    //     if (menuid === 'home' || menuid === 'registration') { 
    //       if(currentPage == "subscription" && (currentStep == "add-subscription" || currentStep == "delete-subscription")){
    //         if(confirm("Are you ready?")){
    //           if(menuid == "home"){
    //             this.router.navigate(['/home/dashboard']);                
    //           } else if(menuid == "registration"){
    //             this.router.navigate(['/home/subscription']);                
    //           }  
    //         }              
    //         return false;
    //       } else{
    //         if(menuid == "home"){
    //           this.router.navigate(['/home/dashboard']);              
    //         } else if(menuid == "registration"){
    //           this.router.navigate(['/home/subscription']);              
    //         }
    //       }
    //     }
	  // });
  }


  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  toggleSettings(): boolean {
    this.sidebarService.toggle(false, 'settings-sidebar');

    return false;
  }

  goToHome() {
    this.menuService.navigateHome();
  }

  startSearch() {
    this.analyticsService.trackEvent('startSearch');
  }
}
