import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by">© {{year}} <b><a href="http://tjek.be/" target="_blank">Tjek® </a></b><b>  Ouderportaal</b> </span>
    
  `,
})
export class FooterComponent {
  year:any;
  ngOnInit() {
    this.year = new Date().getFullYear();
  }
}
