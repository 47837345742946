
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { getDeepFromObject } from '@nebular/auth/helpers';

import { NbAuthService } from '@nebular/auth/services/auth.service';
import { NbAuthResult } from '@nebular/auth/services/auth-result';
import { NB_AUTH_OPTIONS, NbAuthSocialLink } from '@nebular/auth/auth.options';
import { NbResetPasswordComponent } from '@nebular/auth';
import { IAuthResponse } from '../../../../@core/data/authResponse';
import { UserService } from '../../../../@core/data/users.service';
import { IResponse } from '../../../../@core/data/response';

@Component({
  selector: 'nb-reset-password-page',
  styleUrls: ['./reset-password.component.scss'],
  templateUrl: './reset-password.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NgxResetPasswordComponent extends NbResetPasswordComponent {

  redirectDelay: number = 0;
  showMessages: any = {};
  strategy: string = '';

  submitted = false;
  errors: string[] = [];
  messages: string[] = [];
  user: any = {};

  authResult: IAuthResponse;
  token: string;
  constructor(protected service: NbAuthService,
    @Inject(NB_AUTH_OPTIONS) protected options = {},
    protected userService: UserService,
    protected cd: ChangeDetectorRef,
    protected router: Router,
    private route: ActivatedRoute) {

    super(service, options, cd, router);
    this.route.queryParams.subscribe(params => {
      this.token = params['token'];
    });

    this.redirectDelay = this.getConfigValue('forms.resetPassword.redirectDelay');
    this.showMessages = this.getConfigValue('forms.resetPassword.showMessages');
    this.strategy = this.getConfigValue('forms.resetPassword.strategy');
  }

  ngOnInit() {
    this.user.token = this.token;
  }

  resetPass(): void {
    this.errors = [];
    this.messages = [];
    this.submitted = true;
    this.user.token = this.token;
    this.userService.resetPass( this.user).subscribe((result: IResponse) => {
      this.submitted = false;
      //this.authResult = result.getResponse();

      this.errors = [];
      this.messages = [];
     
      if (result.isSuccess) {
        this.messages.push(result.message.toString());
      } else {
        this.errors.push(result.message.toString());
      }

      this.cd.detectChanges();
    });
  }

  getConfigValue(key: string): any {
    return getDeepFromObject(this.options, key, null);
  }
}
