
import { Component, OnInit } from '@angular/core';
import { AnalyticsService } from './@core/utils/analytics.service';
import { Router } from '@angular/router';
import { ConnectionService } from 'ng-connection-service';

@Component({
  selector: 'ngx-app',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit {

  isConnected = true;
  constructor(private analytics: AnalyticsService,
    private connectionService: ConnectionService,
    private router: Router) {
    this.connectionService.monitor().subscribe(isConnected => {
      this.isConnected = isConnected;
      if (this.isConnected) {
        this.router.navigateByUrl('home');
      } else {
        this.router.navigateByUrl('noInternet');

      }
    });
  }

  ngOnInit(): void {
    this.analytics.trackPageViews();
  }
}
