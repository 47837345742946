import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuard } from './@core/data/auth.guard';
import { NotFoundComponent } from './@theme/components/not-found/not-found.component';
import { NoInternetComponent } from './@theme/components/no-internet/no-internet.component';
import { NgxAuthBlockComponent } from './@theme/components/auth';
import { UnsubscribeComponent } from './@theme/components/unsubscribe/unsubscribe.component';

const routes: Routes = [
  {
    path: 'home',
    canActivate: [AuthGuard],
    loadChildren: 'app/home/home.module#HomeModule',
  },
  {
    path: 'auth',
    component: NgxAuthBlockComponent,
    loadChildren: './@theme/components/auth/auth.module#NgxAuthModule',
  },
  {
    path: 'noInternet',
    component: NoInternetComponent,
  },
  {
    path: 'unsubscribe',
    component: UnsubscribeComponent,
  },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: '**', component: NotFoundComponent },
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
