/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./no-internet.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../pipes/eva-icons.pipe";
import * as i3 from "@angular/platform-browser";
import * as i4 from "../../../../../node_modules/@nebular/theme/components/layout/layout.component.ngfactory";
import * as i5 from "@nebular/theme/components/layout/layout.component";
import * as i6 from "@nebular/theme/services/theme.service";
import * as i7 from "@nebular/theme/services/spinner.service";
import * as i8 from "@nebular/theme/theme.options";
import * as i9 from "@nebular/theme/services/direction.service";
import * as i10 from "@nebular/theme/services/scroll.service";
import * as i11 from "@nebular/theme/services/ruler.service";
import * as i12 from "@nebular/theme/components/layout/restore-scroll-top.service";
import * as i13 from "@nebular/theme/components/cdk/adapter/overlay-container-adapter";
import * as i14 from "./no-internet.component";
var styles_NoInternetComponent = [i0.styles];
var RenderType_NoInternetComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NoInternetComponent, data: {} });
export { RenderType_NoInternetComponent as RenderType_NoInternetComponent };
export function View_NoInternetComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.EvaIconsPipe, [i3.DomSanitizer]), (_l()(), i1.ɵeld(1, 0, null, null, 11, "nb-layout", [], [[2, "window-mode", null], [2, "with-scroll", null], [2, "with-subheader", null]], [["window", "scroll"], ["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:scroll" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onScroll($event) !== false);
        ad = (pd_0 && ad);
    } if (("window:resize" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onResize($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_NbLayoutComponent_0, i4.RenderType_NbLayoutComponent)), i1.ɵdid(2, 4374528, null, 0, i5.NbLayoutComponent, [i6.NbThemeService, i7.NbSpinnerService, i1.ElementRef, i1.Renderer2, i8.NB_WINDOW, i8.NB_DOCUMENT, i1.PLATFORM_ID, i9.NbLayoutDirectionService, i10.NbLayoutScrollService, i11.NbLayoutRulerService, i12.NbRestoreScrollTopHelper, i13.NbOverlayContainerAdapter], null, null), (_l()(), i1.ɵeld(3, 0, null, 3, 9, "nb-layout-column", [], [[2, "left", null], [2, "start", null]], null, null, i4.View_NbLayoutColumnComponent_0, i4.RenderType_NbLayoutColumnComponent)), i1.ɵdid(4, 49152, null, 0, i5.NbLayoutColumnComponent, [], null, null), (_l()(), i1.ɵeld(5, 0, null, 0, 7, "div", [["id", "notfound"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 6, "div", [["class", "notfound"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [["class", "notfound-404"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "h1", [["class", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Internet is niet beschikbaar "])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "i", [["class", "wifi-off-outline-eva"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(12, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 2).windowModeValue; var currVal_1 = i1.ɵnov(_v, 2).withScrollValue; var currVal_2 = i1.ɵnov(_v, 2).withSubheader; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = i1.ɵnov(_v, 4).leftValue; var currVal_4 = i1.ɵnov(_v, 4).startValue; _ck(_v, 3, 0, currVal_3, currVal_4); var currVal_5 = i1.ɵunv(_v, 11, 0, _ck(_v, 12, 0, i1.ɵnov(_v, 0), "wifi-off-outline", _co.IconOption)); _ck(_v, 11, 0, currVal_5); }); }
export function View_NoInternetComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ngx-no-internet", [], null, null, null, View_NoInternetComponent_0, RenderType_NoInternetComponent)), i1.ɵdid(1, 114688, null, 0, i14.NoInternetComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NoInternetComponentNgFactory = i1.ɵccf("ngx-no-internet", i14.NoInternetComponent, View_NoInternetComponent_Host_0, {}, {}, []);
export { NoInternetComponentNgFactory as NoInternetComponentNgFactory };
