import { Component, OnInit, TemplateRef, Input, Output } from "@angular/core";
import { NbDialogRef } from "@nebular/theme";
import { environment } from "../../../../environments/environment";
import { Router } from "@angular/router";
import { EventEmitter } from "selenium-webdriver";

@Component({
  selector: "ngx-new-session-start-dialog",
  templateUrl: "./new-session-start-dialog.component.html",
  styleUrls: ["./new-session-start-dialog.component.scss"]
})
export class NewSessionStartDialogComponent implements OnInit {
  @Input() message: String = "";

  constructor(
    protected ref: NbDialogRef<NewSessionStartDialogComponent>,
    protected router: Router
  ) {}

  ngOnInit() {}

  dismiss() {
    this.ref.close();
    environment.TokenExpired = false;
    return this.router.navigateByUrl("/auth/login");
  }
}
